<template>
  <div class="w-full xl:pl-4">
    <div
      ref="slider"
      class="relative z-10 w-full"
    >
      <UiSliderHorizontal
        ref="productsSlider"
        class="swiper-products"
        classes="swiper-products"
        :autoplay="false"
        :options="props.options"
        :slides-per-group="2"
      >
        <UiFeatureBox
          v-for="(product, index) in props.products"
          :key="`desktop-wide-${product?.sku ?? index}`"
          :title="product?.name ?? ''"
          :description="product?.short_description?.html ?? ''"
          :image="$getImgProps(product)"
          class="cursor-pointer"
          @click="navigateTo(productLink(product?.canonical_url ?? ''))"
        >
          <UiButton
            v-if="product?.canonical_url"
            class="mt-5 text-primary hover:bg-primary hover:text-white"
            :to="productLink(product?.canonical_url ?? '')"
            icon-right="arrow"
            variant="circle"
            with-animation
          >
            {{ t('see_more') }}
          </UiButton>
        </UiFeatureBox>
      </UiSliderHorizontal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppNav } from '@base/components/AppNav/useAppNav'
import type { MagentoCategoryProductsSliderProps } from '../MagentoCategoryProductsSlider.types'
import { useMagentoCategoryProductsSlider } from '../composables/useMagentoCategoryProductsSlider'

const props = withDefaults(defineProps<MagentoCategoryProductsSliderProps>(), {
  autoplay: true,
  options: () => ({
    slidesPerGroup: 2,
  }),
})

const { productLink } = useAppNav()
const { $getImgProps } = useNuxtApp()
const { t } = useI18n()
const { slider, productsSlider } = useMagentoCategoryProductsSlider()

defineExpose({
  productsSlider,
})
</script>

<style lang="postcss" scopd>
.swiper-products .swiper-scrollbar-drag {
  @apply bg-white;
}
</style>
